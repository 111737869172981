<template>
  <el-dialog :title="title"
             :visible.sync="outerVisible"
             @close="close"
             width="75%">

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="파트너정보1" name="partnerInfo1">
        <table class="table100">
          <tr>
            <th style="width: 14%">아이디</th>
            <th style="width: 14%">닉네임</th>
            <th style="width: 14%">비밀번호</th>
            <th style="width: 14%">은행명</th>
            <th style="width: 14%">계좌번호</th>
            <th style="width: 14%">예금주</th>
          </tr>
          <tr>
            <td>
              <span v-if="mode == managerConst.MODE_EDIT">{{ partner.account }}</span>
              <el-input v-if="mode == managerConst.MODE_SAVE" size="mini"
                        v-model="partner.account"></el-input>
            </td>
            <td>
              <span v-if="mode == managerConst.MODE_EDIT">{{ partner.nickname }}</span>
              <el-input v-if="mode == managerConst.MODE_SAVE" size="mini"
                        v-model="partner.nickname"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.passwd"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.bank"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.acNo"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.beneficiary"></el-input>
            </td>

          </tr>
          <tr>
            <th style="width: 14%">환전비번</th>
            <th style="width: 14%">가입레벨</th>
            <th style="width: 14%">가입그룹</th>
            <th style="width: 14%">상태</th>
            <th style="width: 14%">회원가입</th>
            <th style="width: 14%">머니이동</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="partner.extractPasswd"></el-input>
            </td>
            <td>
              <!--레벨-->
              <el-select size="mini" v-model="partner.defaultUserRank" placeholder="레벨 선택"
                         style="width: 80%">
                <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">
                  {{ rank.rankName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="partner.defaultUserGroup" placeholder="그룹 선택"
                         style="width: 80%">
                <el-option v-for="group in groupList" :value="group.id" :label="group.groupName">
                  {{ group.groupName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="partner.status" placeholder="상태" style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="블럭">블럭</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="partner.registable" placeholder="회원가입" style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="partner.moneymoveable" placeholder="머니이동"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th style="width: 14%">손익금%</th>
            <th style="width: 14%">(스포츠)베팅%</th>
            <th style="width: 14%">(미니게임)베팅%</th>
            <th style="width: 14%">(카지노)베팅%</th>
            <th style="width: 14%" colspan="2">(슬롯)베팅%</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="partner.rechargeProfitPercent"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.betProfitPercent"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.betLeisureGivebackProfitPercent"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="partner.betCasinoGivebackProfitPercent"></el-input>
            </td>
            <td colspan="2">
              <el-input size="mini" v-model="partner.betSlotGivebackProfitPercent"></el-input>
            </td>
          </tr>
          <tr v-if="mode == managerConst.MODE_EDIT">
            <th>보유머니</th>
            <th colspan="6"></th>
          </tr>
          <tr v-if="mode == managerConst.MODE_EDIT">
            <td>{{ partner.cash|comma }}</td>
            <td colspan="6" style="text-align: left;padding-left: 10px">
              <el-select size="mini" v-model="partner.flowType" placeholder="추가 or 차감"
                         style="width: 100px;margin-left: 3px;">
                <el-option value="1" label="추가">추가(+)</el-option>
                <el-option value="2" label="추가">차감(-)</el-option>
              </el-select>
              <el-input size="mini" type="number" placeholder="수량" v-model="partner.flowCash"
                        style="width: 200px;margin-left: 3px;"></el-input>
            </td>
          </tr>

        </table>
      </el-tab-pane>

      <el-tab-pane label="파트너정보2" v-if="mode === managerConst.MODE_EDIT" name="partnerInfo2">
        <h5>메모</h5>
        <el-input v-if="null != partner" type="textarea" v-model="partner.memo" rows="10"></el-input>
      </el-tab-pane>

      <el-tab-pane label="최입금내역" v-if="mode === managerConst.MODE_EDIT" name="applyHistory2">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총입금: {{ rechargeAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
        </div>
        <el-table
            :data="rechargeList"
            style="width: 100%;margin-top: 10px"
            max-height="730"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <!--금액-->
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>
          <!--날짜-->
          <el-table-column
              label="일짜"
              width="150">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('yyyy-MM-DD HH:mm') }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20,50]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="최근출금내역" v-if="mode === managerConst.MODE_EDIT" name="applyHistory">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총출금: {{ exchangeAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
        </div>
        <el-table
            :data="exchangeList"
            style="width: 100%;margin-top: 10px"
            max-height="730"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <!--금액-->
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>
          <!--날짜-->
          <el-table-column
              label="금액"
              width="150">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('yyyy-MM-DD HH:mm') }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20 ,50]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="머니내역" v-if="mode === managerConst.MODE_EDIT" name="moneyHistory">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총금액: {{ parLogCashAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>

          <el-select size="mini" v-model="partnerCashLog.type" placeholder="머니내역선택"
                     style="width: 240px;margin-left: 3px;" @change="getPartnerLogCashHistory">
            <el-option :value="null" label="전체내역">전체내역</el-option>
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_MANAGER" label="수동내역">수동내역</el-option>
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_POINT_BET_CASINO_AGENT_2_USER"
                       label="총판->유저롤링(카지노베팅)">총판->유저롤링(카지노베팅)
            </el-option>
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_POINT_BET_LEISURE_AGENT_2_USER"
                       label="총판->유저롤링(미니게임베팅)">총판->유저롤링(미니게임베팅)
            </el-option>
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_DAILI" label="부본사->총판 머니이동">
              부본사->총판 머니이동
            </el-option>
          </el-select>
        </div>
        <el-table
            :data="partnerLogCashList"
            style="width: 100%;margin-top: 10px"
            max-height="730"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column
              label="내용"
              width="900">
            <template slot-scope="scope">
              {{ scope.row.content }}
            </template>
          </el-table-column>
          <el-table-column
              label="전 보유금"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.oldValue|comma }}
            </template>
          </el-table-column>
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
                            <span class="text-blue"
                                  v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_PLUS">+{{ scope.row.amount|comma }}</span>
              <span class="text-danger"
                    v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_MINUS">{{ (scope.row.amount * -1)|comma }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="후 보유금"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.currentValue|comma }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20 ,50 ,100]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <el-button @click="save()">저장하기</el-button>
      <el-button @click="outerVisible = false">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
import managerConst from "../../../common/administrator/managerConst";
import {getPartnerById, savePartner, updatePartner} from "../../../network/manager/partnerRequest";
import {getRankList} from "../../../network/manager/rankRequest";
import {getGroupList} from "../../../network/manager/groupRequest";

import {getApplyExchangeForPartnerList, getApplyRechargeForPartnerList} from "../../../network/manager/applyRequest";
import {getLogCashPointList} from "../../../network/manager/logCashPointRequest";

export default {
  name: "PartnerEditToast",
  components: {},
  data() {
    return {
      title: '',
      partnerId: null, //
      partner: {},
      closed: false,
      outerVisible: false,
      managerConst: managerConst,
      mode: managerConst.MODE_SAVE, //save:등록 ,edit:수정,
      partnerList: [],
      rankList: [],
      groupList: [],
      activeName: 'partnerInfo1',
      pageNum: 1,
      pageSize: 20,
      pageTotal: 0,
      startDate: '',
      endDate: '',
      exchangeList: [],
      rechargeList: [],
      exchangeAmountTotal: 0,
      rechargeAmountTotal: 0,
      exchangeCountTotal: 0,
      rechargeCountTotal: 0,
      currentTabName: null,
      partnerCashLog: {type: null},
      partnerLogCashList: [],
      parLogCashAmountTotal: 0,
      pickerOptions: {
        shortcuts: [{
          text: '오늘',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '어제',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '일주일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '15일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
            picker.$emit('pick', date);
          }
        }, {
          text: '30일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {
    search() {

      if (this.currentTabName == 'applyHistory') {
        this.getPartnerApplyHistory()
      }
      if (this.currentTabName == 'applyHistory2') {
        this.getPartnerApplyHistory2()
      }
      if (this.currentTabName == 'moneyHistory') {
        this.getPartnerLogCashHistory()
      }
    },
    close() {
      this.partner = {}
      this.partnerId = null
      this.closed = true
      this.outerVisible = false
    },
    destroyElement() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    save() {
      if (this.mode === managerConst.MODE_SAVE) {
        this.partner.generalAgentId = this.partnerId
        savePartner(this.partner).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '파트너 등록이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('partnerEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
      if (this.mode === managerConst.MODE_EDIT) {
        updatePartner(this.partner).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '파트너 정보 수정이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('partnerEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
    },
    handleClick(tab, event) {
      this.startDate = this.$moment().startOf('month').format('yyyy-MM-DD')
      this.endDate = this.$moment().endOf('month').format('yyyy-MM-DD')
      this.exchangeList = []
      this.rechargeList = []
      this.partnerLogCashList = []
      this.pageNum = 1
      this.pageTotal = 0
      if (tab.name == 'applyHistory') {
        this.currentTabName = 'applyHistory'
        this.getPartnerApplyHistory()
      }
      if (tab.name == 'applyHistory2') {
        this.currentTabName = 'applyHistory2'
        this.getPartnerApplyHistory2()
      }
      if (tab.name == 'moneyHistory') {
        this.currentTabName = 'moneyHistory'
        this.getPartnerLogCashHistory();
      }
    },
    getPartnerById() {
      getPartnerById(this.partnerId).then(res => {
        this.partner = res.data.data
        this.title = '파트너 [' + this.partner.nickname + '] 님 상세정보'
      })
    },
    getPartnerApplyHistory() {
      let record = {}
      record.partnerId = this.partnerId
      record.startDate = this.startDate
      record.endDate = this.endDate
      record.status = managerConst.ApplyCash.STATUS_COMPLITE
      record.applyType = managerConst.ApplyCash.ATYPE_EXCHANGE
      getApplyExchangeForPartnerList(record, this.pageNum, this.pageSize).then(res => {
        this.exchangeList = res.data.data.cashList
        this.exchangeAmountTotal = res.data.data.totalCash;
        this.exchangeCountTotal = res.data.data.totalCount;
        this.pageTotal = res.data.data.totalCount
      })
    },
    getPartnerApplyHistory2() {
      let record = {}
      record.partnerId = this.partnerId
      record.startDate = this.startDate
      record.endDate = this.endDate
      record.status = managerConst.ApplyCash.STATUS_COMPLITE
      record.applyType = managerConst.ApplyCash.ATYPE_RECHARGE
      getApplyRechargeForPartnerList(record, this.pageNum, this.pageSize).then(res => {
        this.rechargeList = res.data.data.cashList
        this.rechargeAmountTotal = res.data.data.totalCash;
        this.rechargeCountTotal = res.data.data.totalCount;
        this.pageTotal = res.data.data.totalCount
      })
    },
    getPartnerLogCashHistory() {
      let record = {}
      this.partnerCashLog.partnerId = this.partnerId
      this.partnerCashLog.startDate = this.startDate
      this.partnerCashLog.endDate = this.endDate
      // record.cashOrPoint = managerConst.CashPointLog.LOG_CASHPOINT_CASH
      this.partnerCashLog.userOrPartner = managerConst.CashPointLog.LOG_CASHPOINT_PARTNER
      getLogCashPointList(this.partnerCashLog, this.pageNum, this.pageSize).then(res => {
        this.partnerLogCashList = res.data.data.result
        this.parLogCashAmountTotal = res.data.data.totalAmount
        this.pageTotal = res.data.data.pageTotal
      })
    },
    setStartDate() {
      this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
    },
    setEndDate() {
      this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.search();
    },
  },
  created() {
    this.outerVisible = true
    getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.rankList = res.data.data
    })
    getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.groupList = res.data.data
    })
    if (this.mode === managerConst.MODE_EDIT) {
      this.getPartnerById()
    } else {
      this.partner.ptype = this.partnerId == managerConst.Parnter.PATNER_GENERALAGENT_NOID ? managerConst.Parnter.PATNER_TYPE_GENERALAGENT : managerConst.Parnter.PATNER_TYPE_AGENT;
      this.partner.status = managerConst.ENABLE;
      this.partner.registable = managerConst.ENABLE;
      this.partner.moneymoveable = managerConst.DISABLE;
      this.generalAgentId = this.partnerId == managerConst.Parnter.PATNER_GENERALAGENT_NOID ? managerConst.Parnter.PATNER_GENERALAGENT_NOID : this.partnerId;
      this.partner.generalAgentId = this.generalAgentId
    }
  },
  mounted() {

  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.outerVisible = false
        this.destroyElement()
      }
    }
  },

}
</script>

<style scoped>
.table100 td {
  padding: 5px;
  background-color: #cfcfd1 !important;
}

.table100 th {
  width: 20%;
}

.table100 .el-input__inner {
  width: 80% !important;
}

.table_userstatisc {
  width: 100%;
  margin-bottom: 5px;
}

.table_userstatisc th {
  width: 14%;
}

.table_userstatisc td {
  background-color: #e8e8e8 !important;
  width: 14%;
}

</style>